import React from "react";
import "./TemplateDownloadButton.scss";
//MUI components
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
//service
import { getTemplates } from "../../services/TemplateDownloadService";

export default function TemplateDownloadButton() {
  const handleDownloadTemplate = async (e) => {
    e.preventDefault();
    const value = e.target.value;
    const resp = await getTemplates(value);
    return resp;
  };

  return (
    <div>
      {" "}
      <Box sx={{ minWidth: 100 }}>
        <FormControl>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Templates.xlsl
          </InputLabel>
          <NativeSelect defaultValue={1} fullWidth>
            <option disabled value={1}>
              Seleccione el template
            </option>
            <option onClick={handleDownloadTemplate} value={"r01-participante"}>
              Participantes
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r02-esquema-de-inversion"}
            >
              Esquema-de-inversion
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r03-cotizacion-de-los-fondos"}
            >
              Cotizacion-de-los-fondos
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r12-movimientos-en-cuenta"}
            >
              Movimientos-en-cuenta
            </option>
            <option
              onClick={handleDownloadTemplate}
              value={"r15-contribuciones-todos-los-participantes"}
            >
              Contribuciones-todos-los-participantes
            </option>
          </NativeSelect>
        </FormControl>
      </Box>
    </div>
  );
}
