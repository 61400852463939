import React, { useState, useEffect } from 'react';
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line
} from 'recharts';
import { roundToTwoDecimals, formatCurrency } from '../../helpers/currencyFormatter';

export default function CustomLineChart({ data, interval }) {  
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const adjustedData = data.map((item) => ({
    ...item,
    percentageChange: item.percentageChange - 100,
  }));

  const tooltipFormatter = (value, name, props) => {
    const date = props.payload?.date || 'Unknown date';
    if (name === 'finalAmount') {
      return [`$${formatCurrency(value)}`, `Rendimiento Acumulado (${date})`];
    }
    if (name === 'percentageChange') {
      return [`${roundToTwoDecimals(value)}%`, `Cambio Porcentual (${date})`];
    }
    return value;
  };
  

  const tickFormatter = (value, index) => {
    if (!isSmallScreen) return value;
    if (index === 0 || index === Math.floor(data.length / 2) || index === data.length - 1) {
      return value;
    }
    return '';
  };

  return (
    <ResponsiveContainer width="100%" height={isSmallScreen ? 150 : 300}>
      <ComposedChart 
        data={adjustedData}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorA" x1="0" y1="1" x2="0" y2="0">
            <stop offset="5%" style={{ stopColor: 'transparent', stopOpacity: 0.15 }} />
            <stop offset="95%" style={{ stopColor: '#0096D7', stopOpacity: 0.15 }} />
          </linearGradient>
        </defs>
        
        <XAxis 
          dataKey="date"
          axisLine={false}
          tickLine={false}
          type="category"
          interval={interval}
          padding={{ left: 5, right: 5, bottom: 10}}
          tick={{ fontSize: 10 }}
          tickFormatter={tickFormatter}
        />      

        <YAxis 
          yAxisId="left"
          tickFormatter={(value) => `${Math.round(value / 1000000)} MM`}
          axisLine={false} 
          tickLine={false} 
          tick={{ fontSize: 10 }}
        />        
        
        <YAxis 
          yAxisId="right"
          orientation="right"
          tickFormatter={(value) => `${value.toFixed(2)}%`}
          axisLine={false} 
          tickLine={false} 
          tick={{ fontSize: 10 }}
        />

        <Tooltip formatter={tooltipFormatter} labelFormatter={(label) => `Date: ${label}`} />
        
        <Area 
          type="monotone" 
          dataKey="finalAmount"
          yAxisId="left"
          stroke="#0096D7" 
          fillOpacity={1} 
          fill="url(#colorA)" 
        />

        <Line 
          type="monotone" 
          dataKey="percentageChange"
          yAxisId="right"
          stroke="#82ca9d"
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
