import React from "react";
import "./subSectionTwo.scss";
import { ActionButton } from "../../../components/ActionButton/ActionButton";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
export default function SubSectionTwo() {
  return (
    <div className="u-flexColumn u-Height100 u-justifySpaceBetween config-mobile-display contact-card-section">
      <div className="base-data-card tertiary-data-card-side-bg u-marginAm u-alignContentCenter  u-customMobileWidth">
        <div className="u-flexColumn need-help-font-styles">
          <ManageAccountsIcon />
          <h3 className="card-label">
            Necesitas hablar con un asesor de Criteria?
          </h3>
          <span className="card-sub-label u-marginBxl">
            Selecciona Contactanos y te redirigiremos con un asesor
          </span>
          <ActionButton
            buttonType="button"
            buttonText="Contactanos"
            buttonColor="#252F40"
            buttonBackground="u-white-background"
          />
        </div>
      </div>

      <div className="base-data-card seccondary-data-card-bg u-marginAm u-alignContentCenter u-customMobileWidth">
        <div className="u-flexColumn need-help-font-styles">
          <HeadsetMicOutlinedIcon />
          <h3 className="card-label">Tenes problemas con la plataforma?</h3>
          <span className="card-sub-label u-marginBxl">
            Selecciona Contactanos y te redirigiremos con un profesional de
            soporte tecnico
          </span>
          <ActionButton
            buttonType="button"
            buttonText="Contactanos"
            buttonColor="#252F40"
            buttonBackground="u-white-background"
          />
        </div>
      </div>
    </div>
  );
}
