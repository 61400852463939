import React from "react";

//MUI components
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

//icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function DarkToolTip({ content, placement, toolTipIconColor }) {
  return (
    <div>
      <BootstrapTooltip
        placement={placement}
        title={content}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 3],
                },
              },
            ],
          },
        }}
      >
        <IconButton>
          <InfoOutlinedIcon
            sx={{
              fontSize: 10,
              cursor: "pointer",
              color: `${toolTipIconColor}`,
            }}
          />
        </IconButton>
      </BootstrapTooltip>
    </div>
  );
}
