export const initialDasboardOverviewModel = {
    beginningOfYearValue: '999999999',
    cash: {
        availableCash: '999999999',
        investedCash: '999999999'
    },
    currentPortfolioValue: '999999999',
    initialValue2021: '999999999',
    isPositive: false,
    ytdPerformance: {
        percentage: '999',
        valueUSD: '999999999',
        isPositive: false,        
    }
}