export const initialAssetDiversificationModel = [{
    label: 'fixedIncome',
    value: '50',
    valueMoney: "1204282.1252335536"
}, {
    label: 'variableIncome',
    value: '25',
    valueMoney: "1204282.1252335536"
},{
    label: 'cash',
    value: '25',
    valueMoney: "1204282.1252335536"
},{
    label: 'diversification',
    value: '25',
    valueMoney: "1204282.1252335536"
}];