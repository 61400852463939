import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Cell } from 'recharts';

const HorizontalGradientBarChart = ({ participantEvolutionDTO }) => {
  
  if (!participantEvolutionDTO || participantEvolutionDTO.length === 0) {
    return <div style={{ textAlign: 'center', padding: '20px' }}>No Hay datos para mostrar</div>;
  }
  return (
    <div style={{ width: '100%', height: 200, marginTop: 15 }}>
      <ResponsiveContainer>
        <BarChart
          data={participantEvolutionDTO}
          layout="vertical"
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
          barCategoryGap={5}
          barGap={1}
          
        >
          
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#85D1EF" stopOpacity={1} />
              <stop offset="40%" stopColor="#214584" stopOpacity={1} />
              <stop offset="100%" stopColor="#002E61" stopOpacity={1} />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" 
                 axisLine={false} 
                 tickLine={false} 
                 tick={{ fontSize: 12 }}/>
          <YAxis type="category" 
                 dataKey="period" 
                 axisLine={false} 
                 tickLine={false}
                 tick={{ fontSize: 12 }} />
          <Tooltip />

          <Bar dataKey="evolutionPercentage" fill="url(#colorGradient)" barSize={20} >
            {participantEvolutionDTO.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HorizontalGradientBarChart;
