import React, { useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import "./DragDropFiles.scss";

//icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

//material components
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

//utils
import { bytesToSize, getRandomInt } from "../../helpers/DragDropUtils";
//services
import {
  UploadUsersParticipants,
  UploadInversionSchema,
  UploadFundQuotes,
  UploadContributions,
  UploadAccountMovements,
} from "../../services/UploadFilesService";

export default function DragDropFiles({ companyId }) {
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState(null);
  const [value, setValue] = useState("Participantes");
  const [tagCount, setTagCount] = useState([]);

  //handle changeTag
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //handle delete files y rejectedFiles
  const handleDeletUploadedFile = (id) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const sendFiles = async (items, tag) => {
    switch (tag) {
      case "Participantes":
        console.log(`Archivo enviado a Participantes endpoint `);
        setTagCount((previousTag) => [...previousTag, tag]);
        const participantsData = new FormData();
        participantsData.append("file", items);
        UploadUsersParticipants(companyId, participantsData);

        break;
      case "Esquema de inversion":
        setTagCount((previousTag) => [...previousTag, tag]);
        const InversionSchemaData = new FormData();
        InversionSchemaData.append("file", items);
        UploadInversionSchema(companyId, InversionSchemaData);

        break;
      case "Cotizacion de los fondos":
        console.log(`Archivo enviado a Cotizacion de los fondos endpoint `);
        setTagCount((previousTag) => [...previousTag, tag]);
        const fundQuotesData = new FormData();
        fundQuotesData.append("file", items);
        UploadFundQuotes(companyId, fundQuotesData);

        break;
      case "Movimientos en cuenta":
        console.log(`Archivo enviado a Movimientos en cuenta endpoint `);
        setTagCount((previousTag) => [...previousTag, tag]);
        const accountMovementsData = new FormData();
        accountMovementsData.append("file", items);
        UploadAccountMovements(companyId, accountMovementsData);

        break;
      case "Contribucciones de todos los participantes":
        console.log(
          `Archivo enviado a Contribucciones de todos los participantes endpoint `
        );
        setTagCount((previousTag) => [...previousTag, tag]);
        const contributionsData = new FormData();
        contributionsData.append("file", items);
        UploadContributions(companyId, contributionsData);

        break;
      default:
        console.log(`Debe subir un archivo `);
    }
  };

  //handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const file = files;
    const answer = window.confirm(
      `Esta Seguro? [${file.length} Archivos cargados listos para enviar]`
    );
    if (answer) {
      if (file.length > 0) {
        file.map((items) => sendFiles(items, items.tag));
      } else {
        alert("Primero debe cargar los archivos y sus categorias");
      }
    } else {
      console.log("Error al cargar los archivos");
    }
    setFiles([]);
    setRejected(null);
  };

  //handle onDrop
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length) {
        setFiles((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, { id: getRandomInt(6), tag: value })
          ),
        ]);
      }
      if (rejectedFiles) {
        setRejected(...rejectedFiles);
      }
    },
    [value]
  );

  //dropzone setup
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    maxSize: 50 * 1024 * 1024,
  });

  //Drag border colors
  const style = useMemo(
    () => ({
      ...(isFocused ? { borderColor: "#2196f3" } : {}),
      ...(isDragAccept ? { borderColor: "#00e676" } : {}),
      ...(isDragReject ? { borderColor: "#ff1744" } : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <form className="drag-drop-form" onSubmit={handleSubmit}>
        <div
          className="drag-drop-container"
          {...getRootProps({ className: "dropzone", style })}
        >
          <input
            className="drag-drop-input"
            {...getInputProps({ className: "dropzone" })}
          />
          {isDragActive ? (
            <div className="drag-drop-text ">
              <CloudDoneIcon />
              <p>Elegi el archivo o arrastralo aqui</p>
              <p>Csv, .xlsl up to 50MB</p>
              <div className="upload-button">Browse File</div>
            </div>
          ) : (
            <div className="drag-drop-text">
              <CloudDoneIcon />
              <p>Elegi el archivo o arrastralo aqui</p>
              <p>Only .xlsl up to 50MB</p>
              <div className="upload-button">Browse File</div>
            </div>
          )}
        </div>
        <button type="sumbit" className="upload-button">
          Upload File
        </button>
        <div>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Selecciona una categoria antes de subir el respectivo archivo
              <ul>
                <div>Categorias cargadas </div>
                {tagCount.map((item, idx) => (
                  <>
                    <li key={idx}>
                      {item}{" "}
                      <VerifiedIcon
                        sx={{ color: "green", width: "12px", height: "12px" }}
                      />{" "}
                    </li>
                  </>
                ))}
              </ul>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Participantes"
                control={<Radio />}
                label="Participantes"
              />
              <FormControlLabel
                value="Esquema de inversion"
                control={<Radio />}
                label="Esquema de inversion"
              />
              <FormControlLabel
                value="Cotizacion de los fondos"
                control={<Radio />}
                label="Cotizacion de los fondos"
              />
              <FormControlLabel
                value="Movimientos en cuenta"
                control={<Radio />}
                label="Movimientos en cuenta"
              />
              <FormControlLabel
                value="Contribucciones de todos los participantes"
                control={<Radio />}
                label="Contribucciones de todos los participantes"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </form>
      {/* archivos aceptados */}
      {files ? (
        files.map((files) => (
          <div className="uploaded-files base-data-card ">
            <div className="u-displayFlex uploaded-files-preview need-help-font-styles">
              <div>
                {/* importar icono  */}
                <img
                  style={{ width: "42px", height: "42px", marginTop: "5px" }}
                  src="https://image.similarpng.com/very-thumbnail/2021/09/Microsoft-Excel-icon-design-on-transparent-background-PNG.png"
                  alt="xlsx_icon"
                />
              </div>
              <div className="u-flexColumn">
                <div>{files.name}</div>
                <div className="u-displayFlex gap30">
                  <div>
                    {bytesToSize(files.size)} of {bytesToSize(files.size)}
                  </div>
                  <div>
                    <VerifiedIcon
                      sx={{ color: "green", width: "16px", height: "16px" }}
                    />{" "}
                    Completed
                  </div>
                  <div>Categoria: {files.tag}</div>
                </div>
              </div>
            </div>
            <div className="u-flexColumn cancel-icon u-marginBottom50">
              <div onClick={() => handleDeletUploadedFile(files.id)}>
                <DeleteForeverOutlinedIcon
                  sx={{ width: "24px", height: "24px" }}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div> </div>
      )}
      {/* archivos rechazados */}
      {rejected ? (
        <ul className="base-data-card rejected-files-card">
          <li style={{ listStyle: "none" }}>
            <div>
              <p>{rejected.name}</p>
              <ul>
                <li
                  className="rejected-files-card-msg"
                  key={rejected.error}
                  style={{ listStyle: "none" }}
                >
                  Error en la carga del archivo{" "}
                  <NewReleasesIcon sx={{ color: "red" }} />
                  El archivo tiene que ser .xlsx
                </li>
              </ul>
            </div>
          </li>
        </ul>
      ) : (
        <div></div>
      )}
    </>
  );
}
