import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import HorizontalBarsChart from "../HorizontalBarsChart/HorizontalBarsChart";
import CardTitleWSelect from "../CardTitleWSelect/CardTitleWSelect";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MobileTabContent3({ value }) {
  return (
    <div>
      <CustomTabPanel value={value} index={2}>
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex">
          <div className="u-flexColumn">
            <div>
              <CardTitleWSelect customTitle="Aportes Empresa + Participante" />
              <label className="card-label">Aportes totales USD 240,9</label>
            </div>
            <HorizontalBarsChart />
          </div>
        </div>
      </CustomTabPanel>
    </div>
  );
}
