export const defaultPortfolioSelectionModel = {
    portfolios: [
        {
            name: "Syngenta - Madre",
            accountId: "QPS300340"
        },
        {
            name: "Syngenta - Dolar 2020",
            accountId: "QPS300464"
        },
        {
            name: "Syngenta - Conservadora",
            accountId: "QPS300472"
        },
        {
            name: "Syngenta - Moderada",
            accountId: "QPS300480"
        }
    ]
}