import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileDropDownMenu.scss";
import { logOut } from "../../services/AuthService";

export default function ProfileDropDownMenu() {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = async (e) => {
    e.preventDefault();
    const logout = await logOut();
    if (logout) {      
        navigate("/new-login", { replace: true });      
    }
  };

  return (
    <div className="profileMenu">
      <i onClick={() => setOpenMenu((prev) => !prev)}>
        <img
          className="profile_Icon"
          src="https://avatar.iran.liara.run/public/32"
          alt="Profile_avatar"
        />
      </i>

      {openMenu && (
        <div className="dropDownMenu">
          <ul>
            <div className="u-displayFlex dropDownOptions">
              <div onClick={handleLogOut} className="text-black">
                LOG OUT
              </div>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}
