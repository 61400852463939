import React from "react";
import "./HeaderProfileMenu.scss";
import ProfileDropDownMenu from "../ProfileDropDownMenu/ProfileDropDownMenu";

export const HeaderProfileMenu = () => {
  return (
    <div>
      {/* <img className='profile-menu-icon' src='https://www.vhv.rs/dpng/d/550-5508649_person-image-placeholder-clipart-png-download-no-profile.png' alt='profile'></img> */}
      <ProfileDropDownMenu />
    </div>
  );
};
