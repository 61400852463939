import React from "react";
import "./MobileProfileBar.scss";

export default function MobileProfileBar() {
  return (
    <div className="u-flexColumn u-Width100 shadow u-paddingVxl">
      <div className=" base-data-card default-data-card-bg u-marginHl ">
        <div className="u-justifyCenter u-Height100">
          <div className="profile_avatar_bg u-background-bright-blue">
            {" "}
            <img
              src="https://avatar.iran.liara.run/public/5"
              className="profile_avatar "
              alt="profile_avatar"
            />
          </div>
          <div className="u-paddingVs">Bienvenido, Juan! </div>
          <div className="u-displayFlex u-justifyCenter u-Width70 u-paddingVm">
            Descrubre toda la informacion sobre tu portafolio de inversion de
            pension
          </div>
        </div>
      </div>
    </div>
  );
}
