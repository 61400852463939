import React, { useEffect, useState } from "react";
import MainDashboard from "../Dashboard/MainDashboard";
import MainPortfolio from "../Portfolio/MainPortfolio.js";
import MainUsuarios from "../Usuarios/MainUsuarios.js";
import MainConfiguracion from "../Configuracion/MainConfiguracion.js";
import MainGestionEmpresas from "../GestionEmpresas/MainGestionEmpresas.js";
import MainBenchmark from "../Benchmark/MainBenchmark.js";
import { SideNav } from "../../components/SideNav/SideNav.js";
import { getUserById } from "../../services/UserStatisticsService";
import "./Layout.scss";

export default function Layout({ page }) {
  const [companyId, setCompanyId] = useState("");
  //const [accountIdentifier, setAccountIdentifier] = useState("");
  useEffect(() => {
    const currentUser = sessionStorage.getItem("CURRENT_USER");

    if (currentUser) {
      getUserById().then((response) => {        
        setCompanyId(response.data?.companyId);
        //setAccountIdentifier(response.data?.qpsId);
      });
    }
  }, []);

  const handleToggleView = (page) => {
    if (page === "dashboard") {
      return <MainDashboard companyId={companyId} />;
    }
    if (page === "portfolio") {
      return <MainPortfolio companyId={companyId} />;
    }
    if (page === "usuarios") {
      return <MainUsuarios companyId={companyId} />;
    }
    if (page === "benchmark") {
      return <MainBenchmark />;
    }
    if (page === "configuracion") {
      return <MainConfiguracion />;
    }
    if (page === "gestion-empresas") {
      return <MainGestionEmpresas companyId={companyId} />;
    }
  };

  return (
    <div className="u-displayGrid primary-view u-main-font-family">
      <div className="sidenav">
        <SideNav pageTitle={page} />
      </div>
      <div>{handleToggleView(page)}</div>
    </div>
  );
}
