import React from "react";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import MobileTabsMenu from "../../components/MobileTabsMenu/MobileTabsMenu";
import MobileProfileBar from "../../components/MobileProfileCard/MobileProfileBar";

export default function MainBenchmark() {
  return (
    <div className="u-displayGrid main-usuarios ">
      <div className="usuarios-header display_column ">
        <HeaderBar
          headerTitle={"Benchmark"}
          headerTitleInfo={"Resumen"}
        />
      </div>

      <div className="u-displayFlex display_column wrap web-display">
        Página en desarrollo
      </div>

      <div className="mobile-display">
        <MobileProfileBar />
        <MobileTabsMenu />
        <MobileBottomBar />
      </div>
    </div>
  );
}
