export const initialParticipantsEvolutionModel = {
    isPositive: false,
    percentage: 0,
    participantEvolutionDTO: [
        {
            period: 'Junio 2020',
            evolutionPercentage: 5
        },
        {
            period: 'Junio 2021',
            evolutionPercentage: 20
        }
    ],
}