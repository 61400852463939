export const initialHistoricalPerformanceData = [
    {
        date: "2020-05-27",
        finalAmount: 2898252.0,
        percentageChange: 100.0
    },
    {
        date: "2020-05-28",
        finalAmount: 2898212.0,
        percentageChange: 99.99861986
    },
    {
        date: "2020-05-29",
        finalAmount: 2898229.0,
        percentageChange: 99.99920643
    },
    {
        date: "2020-05-30",
        finalAmount: 2898229.0,
        percentageChange: 99.99920643
    },
    {
        date: "2020-05-31",
        finalAmount: 2898229.0,
        percentageChange: 99.99920643
    },
    {
        date: "2020-06-01",
        finalAmount: 2898219.0,
        percentageChange: 99.99886139
    },
    {
        date: "2020-06-02",
        finalAmount: 2898309.0,
        percentageChange: 100.00196675000001
    },
    {
        date: "2020-06-03",
        finalAmount: 2898209.0,
        percentageChange: 99.99851646
    },
    {
        date: "2020-06-04",
        finalAmount: 2898239.0,
        percentageChange: 99.99955158
    },
    {
        date: "2020-06-05",
        finalAmount: 2898239.0,
        percentageChange: 99.99955158
    },
    {
        date: "2020-06-06",
        finalAmount: 2898239.0,
        percentageChange: 99.99955158
    },
    {
        date: "2020-06-07",
        finalAmount: 2898239.0,
        percentageChange: 99.99955158
    },
    {
        date: "2020-06-08",
        finalAmount: 2898189.0,
        percentageChange: 99.99782655
    },
    {
        date: "2020-06-09",
        finalAmount: 2898159.0,
        percentageChange: 99.99679141
    },
    {
        date: "2020-06-10",
        finalAmount: 2898219.0,
        percentageChange: 99.99886165
    },
    {
        date: "2020-06-11",
        finalAmount: 2898339.0,
        percentageChange: 100.00300195
    },
    {
        date: "2020-06-12",
        finalAmount: 2898349.0,
        percentageChange: 100.00334724
    },
    {
        date: "2020-06-13",
        finalAmount: 2898349.0,
        percentageChange: 100.00334724
    },
    {
        date: "2020-06-14",
        finalAmount: 2898349.0,
        percentageChange: 100.00334724
    },
    {
        date: "2020-06-15",
        finalAmount: 2898349.0,
        percentageChange: 100.00334724
    },
    {
        date: "2020-06-16",
        finalAmount: 2898309.0,
        percentageChange: 100.00196713999999
    },
    {
        date: "2020-06-17",
        finalAmount: 2898399.0,
        percentageChange: 100.00507239999999
    },
    {
        date: "2020-06-18",
        finalAmount: 2898439.0,
        percentageChange: 100.00645246999999
    },
    {
        date: "2020-06-19",
        finalAmount: 2898479.0,
        percentageChange: 100.00783251999998
    },
    {
        date: "2020-06-20",
        finalAmount: 2898479.0,
        percentageChange: 100.00783251999998
    },
    {
        date: "2020-06-21",
        finalAmount: 2898479.0,
        percentageChange: 100.00783251999998
    },
    {
        date: "2020-06-22",
        finalAmount: 2898539.0,
        percentageChange: 100.00990256999998
    },
    {
        date: "2020-06-23",
        finalAmount: 2898609.0,
        percentageChange: 100.01231757999999
    },
    {
        date: "2020-06-24",
        finalAmount: 2898619.0,
        percentageChange: 100.01266256999999
    },
    {
        date: "2020-06-25",
        finalAmount: 2898589.0,
        percentageChange: 100.01162758999999
    },
    {
        date: "2020-06-26",
        finalAmount: 2898619.0,
        percentageChange: 100.01266257999998
    },
    {
        date: "2020-06-27",
        finalAmount: 2898619.0,
        percentageChange: 100.01266257999998
    },
    {
        date: "2020-06-28",
        finalAmount: 2898619.0,
        percentageChange: 100.01266257999998
    },
    {
        date: "2020-06-29",
        finalAmount: 2898739.0,
        percentageChange: 100.01680247999998
    },
    {
        date: "2020-06-30",
        finalAmount: 2898663.0,
        percentageChange: 100.01418064999999
    }
];
