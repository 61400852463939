import React, { useState } from "react";
import "./MobileTabsMenu.scss";

// MUI component
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MobileTabContent1 from "./MobileTabContent1";
import MobileTabContent2 from "./MobileTabContent2";
import MobileTabContent3 from "./MobileTabContent3";

// Icons

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MobileTabsMenu() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tabs-container ">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
            variant="fullWidth"
          >
            <Tab label="Portafolio" {...a11yProps(0)} />
            <Tab label="Detalle" {...a11yProps(1)} />
            <Tab label="Aportes" {...a11yProps(2)} />
          </Tabs>
        </Box>
        {/* PANEL PORTAFOLIO */}
        <MobileTabContent1 value={value} />
        {/* PANEL DETALLE */}
        <MobileTabContent2 value={value} />
        {/* PANEL APORTES */}
        <MobileTabContent3 value={value} />
      </Box>
    </div>
  );
}
