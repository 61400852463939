import React from "react";
import "./SideNav.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PieChartIcon from "@mui/icons-material/PieChart";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import testCompanyLogo from "../../assets/test-company.svg";
import { HeaderProfileMenu } from "../HeaderProfileMenu/HeaderProfileMenu";
import useMediaQuery from '@mui/material/useMediaQuery';



export const SideNav = ({ pageTitle }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

    const isBetween1280And600 = useMediaQuery('(max-width:1280px) and (min-width:600px)');
    const isMobile = useMediaQuery('(max-width:600px)');
    const drawerWidth = isMobile ? '60%' : isBetween1280And600 ? '10%' : '20%';

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const getListItemStyle = (path) => {
    return location.pathname === path
      ? {
          borderRadius: "16px",
          padding: "12px",
          background:
            "linear-gradient(255.05deg, #002E61 13.81%, #214584 59.85%, #85D1EF 113.2%)",
          backgroundBlendMode: "multiply",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          color: "#FFFFFF",
          "& .MuiListItemIcon-root": {
            color: "#FFFFFF",
          },
          "& .css-10hburv-MuiTypography-root": {
            WebkitTextFillColor: "#FFFFFF",
            backgroundColor: "none",
          },
        }
      : {
          "& .MuiListItemIcon-root": {
            color: "#042E5D",
          },
        };
  };

  const drawer = (
    <div>
      <Toolbar />
      <img
        src={testCompanyLogo}
        alt="Company Logo"
        style={{
          width: "100%",
          height: "auto",
          padding: "10px",
          maxWidth: "254px",
        }}
      />
      <Divider />
      <List
        sx={{
          display: "flex-col",
          gap: "1em",
          fontFamily: "Gotham Rounded !important",
        }}
      >
        <ListItem onClick={() => navigate("/dashboard")}>
          <ListItemButton sx={getListItemStyle("/dashboard")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <PieChartIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Vision General" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => navigate("/portfolio")}>
          <ListItemButton sx={getListItemStyle("/portfolio")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <WorkOutlineOutlinedIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Portfolio segun Perfil" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => navigate("/usuarios")}>
          <ListItemButton sx={getListItemStyle("/usuarios")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <FolderOutlinedIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Planes de Usuarios" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => navigate("/benchmark")}>
          <ListItemButton sx={getListItemStyle("/benchmark")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <ImportContactsOutlinedIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="BenchMark" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => navigate("/gestion-empresas")}>
          <ListItemButton sx={getListItemStyle("/gestion-empresas")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <AccountBoxOutlinedIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Gestion de Empresas" />
          </ListItemButton>
        </ListItem>

        <ListItem onClick={() => navigate("/configuracion")}>
          <ListItemButton sx={getListItemStyle("/configuracion")}>
            <ListItemIcon>
              <div className="nav-icon-container">
                <SettingsOutlinedIcon />
              </div>
            </ListItemIcon>
            <ListItemText primary="Configuracion & Soporte" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        sx={{
          height: "60px",
          width: "60px",
          left: 0,
          padding: "20px",
          backgroundColor: "white",
          display: "flex",
        }}
      >
        <div className="u-displayFlex mobile-header">
          <IconButton
            color="#343C6A"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 0, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="page-title u-displayFlex">
            <h2>{pageTitle}</h2>
          </div>
          <HeaderProfileMenu />
        </div>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          ".css-10hburv-MuiTypography-root": {
            fontFamily: "Gotham Rounded",
            background:
              "linear-gradient(132deg, #042E5D -15.13%, #85D1EF 149.06%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
