import React from 'react';
import "./subSectionTwo.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";
import { formatCurrency } from "../../../helpers/currencyFormatter";
//icons
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";

//component
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";


export default function SubSectionTwo({ companyId, dasboardOverviewModel, portfolioMovements }) {
  
  return (
    <div className="u-flexColumn u-Width100 user-plan-section-two">
      <div className=" base-data-card default-data-card-bg u-marginHl ">
        <div className="u-displayFlex u-justifySpaceBetween">
          <label>Perfil</label>
          <MoreVertIcon sx={{ cursor: "pointer" }} />
        </div>

        <div className="u-justifyCenter u-Height100 right-side-card">
          <div className="profile_avatar_bg u-background-bright-blue">
            {" "}
            <img
              src="https://avatar.iran.liara.run/public/5"
              className="profile_avatar "
              alt="profile_avatar"
            />
          </div>
          <div className="u-paddingVs">Bienvenido, Juan! </div>
          <div className="u-displayFlex u-justifyCenter u-Width70 u-paddingVm">
            Descrubre toda la informacion sobre tu portafolio de inversion de
            pension
          </div>
          <div className="base-data-card  seccondary-data-card-bg u-marginAm u-Width100  u-Height100 ">
            <div className="u-flexColumn u-paddingVl">
              <label className="card-sub-label u-textAlignCenter">
                Perfil de Inversor
              </label>
              <label className="card-label u-textAlignCenter">MODERADA</label>
            </div>
          </div>
          <div className="u-Width100 u-paddingHl">
            <div className=" u-Width100 u-color-bright-blue u-paddingVl">
              Movimientos de cartera
            </div>
            <div className="card-items u-Width100 u-displayFlex u-Height100 ">
              <CardOneItems
                icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
                descripcion={"Valor de la Cartera 2021"}
                value={ formatCurrency(dasboardOverviewModel.initialValue2021) }
                bg={"default-data-card-bg"}
                valueType={"U$D"}
              />
              <CardOneItems
                icon={
                  <CurrencyExchangeOutlinedIcon className="sub-card-icon" />
                }
                descripcion={"Valor a Principio de Año"}
                value={ formatCurrency(dasboardOverviewModel.beginningOfYearValue) }
                bg={"default-data-card-bg"}
                valueType={"U$D"}
              />
              <CardOneItems
                icon={<ListAltRoundedIcon className="sub-card-icon" />}
                descripcion={"Valor de cuota parte"}
                value={portfolioMovements.shareValue}
                bg={"default-data-card-bg"}
                valueType={"U$D"}
              />
              <CardOneItems
                icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
                descripcion={"Valor $ cuota parte"}
                value={95.06}
                bg={"default-data-card-bg"}
                valueType={""}
              />

              <CardOneItems
                icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
                descripcion={"Rendimitnos YTD $"}
                value={formatCurrency(dasboardOverviewModel.ytdPerformance.valueUSD)}
                valueType={"U$D"}
                bg={"tertiary-data-card-side-no-bg"}
                tooltip={
                  <DarkToolTip
                    content={`YTD "Year to Date" Desde Enero este año `}
                    placement={"top"}
                    toolTipIconColor={"white"}
                  />
                }
              />
              <CardOneItems
                icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
                descripcion={"Rendimiento YTD %"}
                value={dasboardOverviewModel.ytdPerformance.percentage}
                valueType={"%"}
                bg={"tertiary-data-card-side-no-bg"}
                tooltip={
                  <DarkToolTip
                    content={`YTD "Year to Date" Desde Enero este año `}
                    placement={"top"}
                    toolTipIconColor={"white"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
