import React from "react";
import "./subSectionOne.scss";

import DragDropFiles from "../../../components/DragDropFiles/DragDropFiles";
import TemplateDownloadButton from "../../../components/TemplateDownloadButton/TemplateDownloadButton";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function SubSectionOne({ companyId }) {
  return (
    <div className="u-displayGrid u-alignItems">
      <div className="u-displayFlex u-alignItems u-flexCenter u-justifySpaceBetween need-help-font-styles">
        <h3 className="need-help-font-styles u-marginHl subtitle">
          Descargar seleccionando el boton de el template .xlsx para cargar
          masivamente a los participantes
        </h3>
        <div>
          <TemplateDownloadButton />
        </div>
        {/* <div className="u-displayFlex custom-button">
          <div>
            <VerticalAlignBottomIcon
              sx={{ width: 24, height: 24, color: "white" }}
            />
          </div>
          <div>Template.xlsx</div>
        </div> */}
      </div>

      <div className="base-data-card default-data-card-bg ">
        <div className="u-displayFlex u-alignItems u-justifySpaceBetween">
          <div className="u-displayFlex u-justifyStart gap-utility ">
            <div className="icon-border">
              <CloudUploadOutlinedIcon sx={{ width: 42, height: 42 }} />
            </div>
            <div className="u-flexColumn u-alignItemStart u-marginHm">
              <h3 className="need-help-font-styles">Upload Files</h3>
              <span className="need-help-font-styles">
                Select and upload the files of your choice
              </span>
            </div>
          </div>
          <div className="u-marginBottom50">
            <CancelOutlinedIcon sx={{ width: 24, height: 24 }} />
          </div>
        </div>

        <div className="u-Width100 u-paddingBxl">
          <hr class="solid"></hr>
        </div>

        <div className="">
          <DragDropFiles companyId={companyId} />
        </div>
      </div>
    </div>
  );
}
