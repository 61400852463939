import React from "react";
import "./HeaderBar.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HeaderTitle } from "../HeaderTitle/HeaderTitle";
import { HeaderSearchBar } from "../HeaderSearchBar/HeaderSearchBar";
import { HeaderProfileMenu } from "../HeaderProfileMenu/HeaderProfileMenu";
import DropdownLanguage from "../LanguageSelector/LanguageDropdownMenu";
import DarkToolTip from "../ToolTip/DarkToolTip";

export default function HeaderBar({ headerTitle, headerTitleInfo }) {
  return (
    <div className="header-container u-displayFlex ">
      <div className="u-flexColumn">
        <HeaderTitle title={headerTitle} titleInfo={headerTitleInfo} />
      </div>
      <div className="header-bar u-displayFlex">
        <HeaderSearchBar />
        <div className="languageSelector u-displayFlex">
          <DropdownLanguage />
          <KeyboardArrowDownIcon sx={{ height: "10px" }} />
          <DarkToolTip
            content={
              "Todas las vistas y valores expresados en esta cotización están en Dólares Americanos (UD) "
            }
            placement={"bottom-start"}
          />
        </div>
        <HeaderProfileMenu />
      </div>
    </div>
  );
}
