import React from "react";
import "./MainDashboard.scss";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import SubSectionThree from "./subSectionThree/subSectionThree";
import SubSectionOne from "./subSectionOne/subSectionOne";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";

export default function MainDashboard({companyId}) {
    
    return (
      <div className="u-displayGrid section-two-main main-dashboard ">
        <div className="dashboard-header ">
          <HeaderBar
            headerTitle={"Dashboard"}
            headerTitleInfo={"Vision General de Planes de Pension"}
          />
        </div>
        <div className="">
          <SubSectionOne companyId={companyId}/>
        </div>
        <div className="">
          <SubSectionTwo companyId={companyId}/>
        </div>
        <div className="">
          <SubSectionThree companyId={companyId}/>
        </div> 
        <div>
          <MobileBottomBar />
        </div>
      </div>
    );
}
