import React, { useState, useEffect } from 'react';
import "./subSectionOne.scss";
import { formatCurrency } from '../../../helpers/currencyFormatter';
// Icons
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
// MUI components
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// Components
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";
// Models
import { initialPortfolioMovementsModel } from "../../../models/PortFolioMovementsModel";
import { defaultPortfolioSelectionModel } from '../../../models/PortfolioSelectionModel';
// API
import { getPortfolioMovementsData, getPortfolioSelectionData } from '../../../services/FinancialServices';

export default function SubSectionOne({ companyId, selectedPortfolioType, setSelectedPortfolioType }) {
  const [portfolioMovements, setPortfolioMovements] = useState(initialPortfolioMovementsModel);
  const [portfolioSelection, setPortfolioSelection] = useState(defaultPortfolioSelectionModel.portfolios);

  useEffect(() => {
    if (companyId) {
      getPortfolioSelectionData(companyId).then(response => {
        const dataResponse = response?.data?.portfolios;
        if (dataResponse && dataResponse.length > 0) {
          setPortfolioSelection(dataResponse);    
          setSelectedPortfolioType(dataResponse[0]);
        }
      });
    }
  }, [companyId, setSelectedPortfolioType]);

  useEffect(() => {
    if (selectedPortfolioType) {
      getPortfolioMovementsData(companyId, selectedPortfolioType.accountId).then(response => {
        const dataResponse = response?.data?.portfolioMovements;
        if (dataResponse) {
          setPortfolioMovements(dataResponse);
        }
      });
    }
  }, [companyId, selectedPortfolioType]);

  const handlePortfolioChange = (event) => {
    const selectedPortfolio = portfolioSelection.find(portfolio => portfolio.name === event.target.value);
    setSelectedPortfolioType(selectedPortfolio);
  };

  return (
    <div className=" ">
      {/* card one */}
      <div className="base-data-card default-data-card-bg u-marginAm ">
        <div className="u-flexColumn u-justifySpaceBetween u-marginHm">
          <div className="u-displayFlex u-marginRm u-alignItems">
            <div>
              <ListAltIcon />
            </div>
            <div className="card-graph-title u-Width100 ">
              Portfolios
            </div>
          </div>

          <div>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedPortfolioType?.name || ''}
                onChange={handlePortfolioChange}
              >
                {portfolioSelection.map((portfolio) => (
                  <FormControlLabel
                    key={portfolio.accountId}
                    value={portfolio.name}
                    control={<Radio />}
                    label={portfolio.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      {/* card two */}
      {/* Using dashboard components */}
      <div className="base-data-card default-data-card-bg u-marginAm portfolio-wallet-mov">
        <div className="card-graph-title u-Width100 ">
          Movimientos de cartera
        </div>
        <div className="card-items u-Width100 u-displayFlex">
          <CardOneItems
            icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
            descripcion={"Depositos"}
            value={formatCurrency(portfolioMovements.deposits)}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<CurrencyExchangeOutlinedIcon className="sub-card-icon" />}
            descripcion={"Extracciones"}
            value={formatCurrency(portfolioMovements.withdrawals)}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<ListAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Valor de cuota parte"}
            value={formatCurrency(portfolioMovements.shareValue)}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Participantes Adheridos"}
            value={portfolioMovements.enrolledParticipants}
            valueType={""}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<ListAltIcon className="sub-card-icon" />}
            descripcion={"NAV"}
            value={portfolioMovements.nav}
            valueType={""}
            bg={"default-data-card-bg"}
          />
        </div>
      </div>
    </div>
  );
}
