export const initialPortfolioGraphModel = {
  accumulatedBalance: 5619,
  chart: [
    { date: '2020-07-28', deposits: 197623.4, portfolioEvolution: 0, withdrawals: 0 },
      { date: '2020-08-28', deposits: 100000, portfolioEvolution: 5000, withdrawals: 2000 }         
  ],
  portfolioEvolution: [
    {
      date: "2021-01-04",
      finalAmount: 4002479.0
  },
  {
      date: "2021-01-05",
      finalAmount: 4002499.0
  },
  {
      date: "2021-01-06",
      finalAmount: 4002479.0
  }]    
  
  }


