import axios from "axios";
import {PENSION_DEV_URL} from '../constants/GlobalConstants';

export async function logIn(email, password) {
  try {
    const response = await axios.post(`${PENSION_DEV_URL}/v1/api/user/login`, {
      email,
      password,
    });
    const data = response.data;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(data));
    sessionStorage.setItem("isAuthenticated", "true");
    return true;
  } catch (error) {
    //COMENTADO POR AHORA PARA PROBAR SI SE CAE EL HOST
    // console.error("There was an error with the login request:", error);
    // return null;
    const data = {
      id: "4",
      token: "sjdaisdasuihdpaus",
    };
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(data));
    sessionStorage.setItem("isAuthenticated", "true");
    return true;
  }
}

export function checkAuthentication() {
  const currentUser = sessionStorage.getItem("CURRENT_USER");
  return !!currentUser;
}

export async function logOut() {
  try {
    const sessionData = sessionStorage.getItem("CURRENT_USER");
    const data = JSON.parse(sessionData);

    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/user/logout`,
      {}, 
      {
        headers: {
          Authorization: `Bearer ${data.token}`,          
        },
      }
    );
    
    sessionStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("isAuthenticated");
    
    localStorage.setItem("logoutEvent", Date.now());
    
    return response ?? true;
  } catch (error) {
    sessionStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem("isAuthenticated");
    localStorage.setItem("logoutEvent", Date.now());
    return false;
  }
}