export const UserModel = {
    address: {
      address: "Siempre viva",
      addressNumber: "503",
      city: "Rosario",
      country: "Argentina",
      depto: null,
      piso: null,
      postalCode: null,
      state: null,
    },
    companyId: "Prueba",
    email: "test@gmail.com",
    employeeNumber: "000000",
    firstName: "Test",
    fullName: "Test Test",
    id: 0,
    identity: {
      identificationType: "DNI",
      identificationNumber: "000000",
      cuilNumber: "00000000000"
    },
    initialMonth: 6,
    initialYear: 2020,
    lastName: "Test",
    qpsId: null,
    roles: [
      {
        name: "USER"
      }
    ]
  }
  