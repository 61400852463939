import React from "react";
import "./MainConfiguracion.scss";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SubSectionOne from "../Configuracion/subSectionOne/subSectionOne";
import SubSectionTwo from "../Configuracion/subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";

export default function MainConfiguracion() {
  return (
    <div className="u-displayGrid main-config ">
      <div className="config-header">
        <HeaderBar
          headerTitle={"Configuración & Soporte"}
          headerTitleInfo={"Resumen del Portfolio por Perfil"}
        />
      </div>

      <div className="u-displayFlex config-wrap config-structure-page">
        <div className="">
          {" "}
          <SubSectionOne />
        </div>
        <div>
          {" "}
          <SubSectionTwo />
        </div>
      </div>

      <div className="mobile-view">
        <MobileBottomBar />
      </div>
    </div>
  );
}
