import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';

export default function HorizontalBarsChart() {
    return (
      <Box sx={{ width: '100%' }}>
        <BarChart
          height={300}
          dataset={dataset}          
          yAxis={[{ scaleType: 'band', dataKey: 'order', disableLine: true, disableTicks: true  }]}
          xAxis={[{  disableLine: true, disableTicks: true  }]}
          series={[{ dataKey: 'depositos', layout: 'vertical', stack: 'stack', color: '#A1E3CB' },
                    { dataKey: 'extracciones', layout: 'vertical', stack: 'stack', color: '#85D1EF'},]}
          layout="horizontal"
          grid={{ vertical: true }}
          {...chartSettingsV}
        />
      </Box>        
      );
}

const dataset = [
    [40, 80, 'Ene. 21'],
    [120, 40, 'Jul. 21'],
    [60, 100, 'Ene. 22'],
    [40, 20, 'Jul 22'],
    [60, 100, 'Ene. 23'],
    [40, 280, 'Jul. 23'],

  ].map(([depositos, extracciones, order]) => ({
    depositos,
    extracciones,    
    order,
  }));

  const chartSettingsV = {
    dataset,
    outerRadius: 100,
    grid: { horizontal: true },    
    
};