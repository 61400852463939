import axios from "axios";

const PENSION_DEV_URL = "https://pensiondev.lynxsit.com";

export async function UploadUsersParticipants(companyId, formData) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);
  try {
    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/user/upload/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    return true;
  }
}

export async function UploadInversionSchema(companyId, formData) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);
  try {
    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/file/upload-inversion-schema/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    return true;
  }
}

export async function UploadFundQuotes(companyId, formData) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);

  try {
    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/file/upload-fund-quotes/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    return true;
  }
}

export async function UploadContributions(companyId, formData) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);

  try {
    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/file/upload-contributions/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    return true;
  }
}

export async function UploadAccountMovements(companyId, formData) {
  const sessionData = sessionStorage.getItem("CURRENT_USER");
  const data = JSON.parse(sessionData);

  try {
    const response = await axios.post(
      `${PENSION_DEV_URL}/v1/api/file/upload-account-movements/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
    return true;
  }
}
