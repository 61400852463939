export const initialNavEvolutionModel = {
    nav: [
        {
            date: "2021-01-21",
            value: 0.1
        },
        {
            date: "2022-01-21",
            value: 1
        },
        {
            date: "2023-01-21",
            value: 1.5
        }
    ],
    percentage: 0
}