import React from 'react'
import "./HeaderTitle.scss"

export const HeaderTitle = ({title, titleInfo}) => {
  return (
    <div className=''>
    <div className='header-titleInfo'>{titleInfo}</div>
    <div className='header-title'>{title}</div>
    </div>
    
  )
}
