import React from "react";
import "./MobileBottomBar.scss";

import { NavLink } from "react-router-dom";

//icons
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PieChartIcon from "@mui/icons-material/PieChart";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export default function MobileBottomBar() {
  return (
    <div className="mobileOnly u-Width100">
      <div className="u-flexColumn bottomBarFixed u-paddingAl  ">
        <div className="u-displayFlex bottomBar-container ">
          <NavLink to="/dashboard" className="nav-link">
            <div className="u-flexColumn u-textAlignCenter u-alignItems ">
              <i>
                <AutoStoriesIcon />
              </i>
              <p className="text-white mobileOnly">Dashboard</p>
            </div>
          </NavLink>

          <NavLink to="/portfolio" className="nav-link">
            <div className="u-flexColumn u-textAlignCenter u-alignItems ">
              <i>
                <FolderOpenIcon />
              </i>
              <p className="text-white mobileOnly">Porfolio</p>
            </div>
          </NavLink>

          <NavLink to="/usuarios" className="nav-link">
            <div className="u-flexColumn u-textAlignCenter u-alignItems ">
              <i>
                <PieChartIcon />
              </i>
              <p className="text-white mobileOnly">Usuarios</p>
            </div>
          </NavLink>

          <NavLink to="/gestion-empresas" className="nav-link">
            <div className="u-flexColumn u-textAlignCenter u-alignItems ">
              <i>
                <WebAssetIcon />
              </i>
              <p className="text-white mobileOnly">Gestion</p>
            </div>
          </NavLink>

          <NavLink to="/configuracion" className="nav-link">
            <div className="u-flexColumn u-textAlignCenter u-alignItems ">
              <i>
                <SettingsOutlinedIcon />
              </i>
              <p className="text-white mobileOnly">Configuracion</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
