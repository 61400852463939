export const initialPortfolioDistributionModel = [{
    label: 'Syngenta - Super Conservadora',
    value: '50',
}, {
    label: 'Syngenta - Moderada',
    value: '25',
},{
    label: 'Syngenta - Conservadora',
    value: '25',
},{
    label: 'Syngenta - Madre',
    value: '25',
}];
