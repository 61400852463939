import React from "react";
import "./CardOneItems.scss";

export const CardOneItems = ({
  icon,
  descripcion,
  value,
  valueType,
  tooltip,
  bg,
}) => {  
  return (
    
    <div
      className={`base-data-card ${bg}  u-displayFlex u-marginAm u-alignItems nested-card card-shadow`}
    >
      <div className="card-icon-container">{icon}</div>
      <div className="u-flexColumn u-marginHm">
        <div className="u-displayFlex u-JustifyCenter">
          <div className="card-label u-color-title-card">{descripcion}</div>
          {tooltip}
        </div>
        <span className="card-price">
          {valueType} {value}
        </span>
      </div>
    </div>
  );
};
