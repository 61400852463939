import axiosInstance from "./BaseServiceInstance";

export async function getOverviewData(companyId, accountIdentifier) {
  try {
    const url = `/v1/api/financial/overview?companyId=${companyId}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the overview call', error);
    return null;
  }
}

export async function getHistoricalPerformanceData(companyId, range, accountIdentifier) {
  try {
    const url = `/v1/api/financial/historical-performance?companyId=${companyId}&range=${range}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the historical performance call', error);
    return null;
  }
}

///v1/api/financial/performance/{companyId}/{range}

export async function getHistoricalPerformanceAmountData(companyId, range, accountIdentifier) {
  try {
    const url = `v1/api/financial/performance/${companyId}/${range}` + 
                (accountIdentifier ? `?accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the historical performance amount call', error);
    return null;
  }
}

export async function getPortfolioMovementsData(companyId, accountIdentifier) {
  try {
    const url = `/v1/api/financial/portfolio-movements?companyId=${companyId}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the portfolio movements call', error);
    return null;
  }
}

export async function getPortfolioGraphData(companyId, accountIdentifier) {
  try {
    const url = `/v1/api/financial/portfolio-movement?companyId=${companyId}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the portfolio graph call', error);
    return null;
  }
}

export async function getAssetDiversificationData(companyId, accountIdentifier) {
  try {
    const url = `/v1/api/financial/asset-diversification?companyId=${companyId}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the asset diversification call', error);
    return null;
  }
}

export async function getPortfolioDistributionData(companyId, accountIdentifier) {
  try {
    const url = `/v1/api/financial/portfolio-distribution?companyId=${companyId}` + 
                (accountIdentifier ? `&accountIdentifier=${accountIdentifier}` : '');
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('There was an error on the portfolio distribution call', error);
    return null;
  }
}


export async function getNavEvolutionData(companyId, accountIdentifier) {
  try {    
    const identifier = accountIdentifier || JSON.parse(sessionStorage.getItem('CURRENT_USER'))?.qpsId;

    const response = await axiosInstance.get(`/v1/api/financial/nav-evolution?accountIdentifier=${identifier}`);
    return response;
  } catch (error) {
    console.error('There was an error on the nav-evolution call', error);
    return null;
  }
}

export async function getParticipantEvolutionData(companyId, accountIdentifier) {
  try {    
    const identifier = accountIdentifier || JSON.parse(sessionStorage.getItem('CURRENT_USER'))?.qpsId;

    const response = await axiosInstance.get(
      `/v1/api/financial/participant-evolution?companyId=${companyId}&accountIdentifier=${identifier}`
    );
    return response;
  } catch (error) {
    console.error('There was an error on the participant evolution call', error);
    return null;
  }
}


// /v1/api/financial/portfolios/{companyId}

export async function getPortfolioSelectionData(companyId) {
  try {    
    const response = await axiosInstance.get(
      `/v1/api/financial/portfolios/${companyId}`
    );
    return response;
  } catch (error) {
    console.error('There was an error on the getPortfolioSelectionData call', error);
    return null;
  }
}