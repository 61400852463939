import React from "react";
import "./subSectionOne.scss";
import testCompanyLogo from "../../../assets/test-company.svg";

//MUI components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

//icons
import EditIcon from "@mui/icons-material/Edit";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";

export default function SubSectionOne() {
  const paises = [
    {
      value: "Arengtina",
      label: "Argentina",
    },
    {
      value: "Estados Unidos",
      label: "Estados Unidos",
    },
    {
      value: "Brazil",
      label: "Brazil",
    },
  ];
  return (
    <div className="u-flexColumn ">
      <div className="u-displayFlex u-justifyBetween u-alignItems ">
        <div className="base-data-card default-data-card-bg u-marginAm config-form-container ">
          <div className=" u-config-logo-border company-card">
            <div className="u-flexColumn ">
              <div className="u-displayFlex u-justifyEnd">
                <div className=" ">
                  <EditIcon
                    sx={{
                      backgroundColor: "#929da9a3",
                      borderRadius: "100%",
                      padding: "10%",
                      margin: "5px",
                    }}
                  />
                </div>
              </div>
              <div className="u-justifyCenter">
                <img
                  src={testCompanyLogo}
                  alt="Company Logo"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>

          <div className="u-flexColumn ">
            <Box
              component="form"
              sx={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                paddingTop: "20px",
                display: "grid",
                gap: "20px",
              }}
              noValidate
              autoComplete="off"
            >
              <div className=" gridColumns">
                <TextField
                  id="outlined-password-input"
                  label="Nombre de la empresa"
                  type="search"
                  fullWidth
                />
                <TextField
                  id="outlined-password-input"
                  label="Telefono"
                  type="search"
                  fullWidth
                />

                <TextField
                  id="outlined-password-input"
                  label="Industria"
                  type="search"
                  fullWidth
                />
                <TextField
                  id="filled-read-only-input"
                  label="Website"
                  type="search"
                  fullWidth
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
              </div>
              <TextField
                id="filled-number"
                label="Pais"
                select
                type="search"
                fullWidth
                defaultValue=""
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                  select: {
                    native: true,
                  },
                }}
              >
                {paises.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>
        </div>
      </div>

      <div className="u-displayFlex u-justifyBetween u-alignItems">
        <div className="base-data-card default-data-card-bg u-marginAm config-form-container ">
          <div>
            <h4 className="need-help-font-styles ">Sing-in Method</h4>
          </div>
          <div>
            <Box
              component="form"
              sx={{
                width: "100%",
                height: "100%",
                maxWidth: "100%",
                paddingTop: "20px",
                display: "grid",
                gap: "20px",
              }}
              noValidate
              autoComplete="off"
            >
              <div className=" gridColumns">
                <TextField
                  id="outlined-read-only-input"
                  label="Email"
                  type="Email"
                  defaultValue={"email@email.com"}
                  fullWidth
                  disabled
                  variant="filled"
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
                <TextField
                  id="outlined-password-input"
                  label="Contrasena"
                  type="password"
                  defaultValue={"***********************"}
                  fullWidth
                  disabled
                  variant="filled"
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
              </div>
            </Box>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAm two-factor-color-card need-help-font-styles">
            <div>
              <div className="u-displayFlex u-justifyStart ">
                <div>
                  <BeenhereOutlinedIcon
                    sx={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
                <div>Secure your acount</div>

                <div className="u-justifyEnd">
                  <div className="u-badge">Enable</div>
                </div>
              </div>
              <span className="u-justifyStart two-factor-spacing">
                Two-Factor authentication adds an extra layer of security to
                your account. To log in, in addition you'll need to provide a 6
                digit code
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
