import React from "react";
import "./MainGestionEmpresas.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import SubSectionOne from "./subSectionOne/subSectionOne";

export default function MainGestionEmpresas({ companyId }) {
  return (
    <div className="u-displayGrid main-gestion">
      <div className="gestion-header  ">
        <HeaderBar
          headerTitle={"Gestion de Empresas"}
          headerTitleInfo={"Portal de alta de usuarios masiva"}
        />
      </div>

      <div className=" u-displayFlex u-flexCenter gestion-wrap ">
        <div className="">
          {" "}
          <SubSectionOne companyId={companyId} />
        </div>
      </div>

      <div className="mobile-view">
        <MobileBottomBar />
      </div>
    </div>
  );
}
